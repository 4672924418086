import Dialog from "@mui/material/Dialog";
import { TrackedTask, saveTrackedTask } from "../../../../features/localStorageDBHandler/localStorageDBHandler";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TaskSelectComboBox from "../TaskSelectComboBox/TaskSelectComboBox";
import { useTranslation } from "react-i18next";
import "./TrackedTaskForm.css";
import {DateTimeToISOSringLocale, FromDateTimeStringToDateTimeSelectorFormat, TimeToSeconds} from "../../../../helpers/DateTimeFormaters"
import {SecondsToISOStringLocale} from "../../../../helpers/DateTimeFormaters"

type TrackedTaskFormProps = {
  saveTrackedTaskDialogOpen: boolean;
  handleSaveTrackedTaskClose: any;
  trackedTask: TrackedTask;
};

//DONE: PRevent this dialog from closing when clicking outside. It should exit when saving or cancelling.
export default function TrackedTaskForm({
  saveTrackedTaskDialogOpen,
  handleSaveTrackedTaskClose,
  trackedTask,
}: TrackedTaskFormProps) {
  const { t, i18n } = useTranslation();
  const currentUserEmail = JSON.parse(localStorage.getItem("kttwEmail")!);
  /** Los estados para el control del temporizador. */
  const { isRunning, isPaused, isStopped, timeInSeconds, startDate, endDate } =
    useSelector((state: any) => state.timerReducer);

    const { taskId, name } = useSelector(
      (state: any) => state.selectedTaskReducer
    );
  //---------------------------------------------------------------------
  // STATE VARIABLES HERE
  //---------------------------------------------------------------------
  const [editedStartDate,setEditedStartDate] = useState(startDate);
  const [editedEndDate,setEditedEndDate] = useState(endDate);
  const [editedTimeInSeconds,setEditedTimeInSeconds] = useState(timeInSeconds);
  const [editedTimeToDisplay,setEditedTimeToDisplay] = useState(SecondsToISOStringLocale(timeInSeconds))
  const [comments,setComments] = useState("");
  //---------------------------------------------------------------------
  // HANDLERS HERE
  //---------------------------------------------------------------------
  const handleStartDateChange = (event:any)=>{
    let tempFechita = new Date(event.target.value).toString()
    setEditedStartDate(tempFechita)
  };
  const handleEndDateChange = (event:any)=>{
    let tempFechita = new Date(event.target.value).toString()
    setEditedEndDate(tempFechita)
  };
  const handleTimeInSecondsChange = (event:any)=>{
    setEditedTimeToDisplay(event.target.value)
    setEditedTimeInSeconds(TimeToSeconds(event.target.value))
  };
  const handleCommentsChange = (event:any)=>{
    setComments(event.target.value)
  };
  const handleSaveButtonClick = ()=>{

    //TimeToSeconds(editedTimeInSeconds)
    let tempTrackedTask:TrackedTask = {
      comments:comments,
      taskActiveTime:editedTimeInSeconds,
      taskEndDate:editedEndDate,
      taskId:taskId,
      taskName:name,
      taskOwnerEmail:currentUserEmail,
      taskStartDate:editedStartDate,
      trackedtaskId:""
    };
    saveTrackedTask(tempTrackedTask);
    handleSaveTrackedTaskClose();
  }

  return (
    <Dialog
      open={saveTrackedTaskDialogOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t("trackedTaskFormTitle")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t("trackedTaskFormDescription")}
        </DialogContentText>

        {/** AQUI LA PARTE DE LAS ENTRADAS PARA EL FORMULARIO DE LA TAREA */}
        <div className="TrackedTaskForm">
          <TaskSelectComboBox taskSelectorActive={true} />

          {/**En mismo DIV fecha de inicio y de fin para el responsive */}
          <div className="TrackedTaskForm-StartEndDates">
            <div>
              <text>{t("trackedTaskFormStartDate")}</text>
              <input type="datetime-local"  onChange={handleStartDateChange} value={FromDateTimeStringToDateTimeSelectorFormat(editedStartDate)}/>
            </div>

            <div>
              <text>{t("trackedTaskFormEndDate")}</text>
              <input type="datetime-local" onChange={handleEndDateChange} value={FromDateTimeStringToDateTimeSelectorFormat(editedEndDate)}/>
            </div>
          </div>

          <div className="TrackedTaskForm-ActiveTime">
            <text>{t("trackedTaskFormTaskActiveTime")}</text>
            <input type="time" value={editedTimeToDisplay} onChange={handleTimeInSecondsChange}/>
          </div>

          <div className="TrackedTaskForm-Comments">
            <text>{t("trackedTaskFormComments")}</text>
            <textarea className="TrackedTaskForm-CommentsInput" onChange={handleCommentsChange}/>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSaveButtonClick}>Save</Button>
        <Button onClick={handleSaveTrackedTaskClose} autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
