import React, { useEffect } from "react";
import { useState } from "react";
import {
  Stack,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Task,
  saveTask,
} from "../../../../features/localStorageDBHandler/localStorageDBHandler";

export default function TaskCreateDialog() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const currentUserEmail = JSON.parse(localStorage.getItem("kttwEmail")!);
  const currentUserName = JSON.parse(localStorage.getItem("kttwCurrentName")!)

  /** STATE Variable here */
  const [taskName, setTaskName] = useState("");
  const [taskActive, setTaskActive] = useState(true);
  const [taskCreated,setTaskCreated] = useState(true);
  const [createTaskButtonEnabled,setCreateTaskButtonEnabled] = useState(false);
  /**Estoy creando esta variabel de estado para almacenar el mensaje que nos debe devolver el
   * manejador del localstorage cada vez que intentamos crear una tarea.*/
  const [taskCreationMessage,setStatusCreationMessage] = useState("");

  /** HANDLERS HERE */
  const handleTaskNameChange = (event: any) => {
    setTaskName(event.target.value);
    if (event.target.value) {
      setCreateTaskButtonEnabled(true);
    }else{
      setCreateTaskButtonEnabled(false);
    }
    
  };
  const handleTaskActiveChange = (event: any) => {
    setTaskActive(event.target.checked);
  };

  const handleCreateTaskClick = () => {
    let task: Task = {
      active: taskActive,
      name: taskName,
      taskOwnerEmail: currentUserEmail,
      taskOwnerName: currentUserName,
    };
    const response = JSON.parse(saveTask(task));
    setTaskCreated(response.taskCreated)  
    setStatusCreationMessage(t(response.taskCreationMessage))
  };


  return (
    <Stack>
      <TextField
        id="taskName"
        label={t("taskName")}
        value={taskName}
        onChange={handleTaskNameChange}
        error={!taskCreated}
      />
      <FormControlLabel
        label={t("taskActive")}
        control={
          <Checkbox
            value=""
            checked={taskActive}
            onChange={handleTaskActiveChange}
            color="primary"
          />
        }
      />
      <Button variant="contained" onClick={handleCreateTaskClick} {...(createTaskButtonEnabled ? {} : {disabled:true})}>
        {t("createTask")}
      </Button>
      <p {...(taskCreated ? {style:{color:"green"}} : {style:{color:"red"}})}>
      {taskCreationMessage}
      </p>
    </Stack>
  );
}
