import React from "react";
import { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import {
  Task,
  getTasks,
} from "../../features/localStorageDBHandler/localStorageDBHandler";
import { Button, Modal, Box } from "@mui/material";
import TaskItemView from "./components/TaskItemView/TaskItemView";
import TaskCreateDialog from "./components/TaskCreateDialog/TaskCreateDialog";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header/Header";
//TODO Crear campo de busqueda
// que filtre las tareas y poder operar con ellas. Esto ya lo habias empezado a hacer pero se enredo 
// la cosa con el filtrado. Los estado y el copon divino. De momento lo dejas asi como esta sin filtro
// Para poder terminar algo funcional.
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 280,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const addButtonStyle = {
  position: "relative" as "relative",
  top: "100%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

export default function TasksScreen() {
  const currentUserEmail = JSON.parse(localStorage.getItem("kttwEmail")!);
  const currentUserName = JSON.parse(localStorage.getItem("kttwCurrentName")!);
  const { t, i18n } = useTranslation();

  /**STATE VARIABLES Here */
  const [tasks, setTasks] = useState(getTasks(currentUserEmail));
  const [open, setOpen] = useState(false);
  const [deleteTaskDialogOpen,setDeleteTaskDialogOpen] = useState(false);
  const [selectedTaskName,setSelectedtaskName] = useState("");
  const [filteredTasks,setFilteredTasks] = useState(getTasks(currentUserEmail));

  /**USE EFFECTS Here */
  useEffect(() => {
    setTasks(getTasks(currentUserEmail));
  }, [tasks]);

  /**HANDLERS Here */
  const handleActiveChange = (params: any) => {
  };
  /**Relacionado con el campo de busqueda y el filtro. Esto no funciona todavia se deja para mas adelante */
  const handleSearchFieldChange = (event:any)=>{
    setFilteredTasks(tasks.filter((task:Task)=>task.name.toLowerCase().includes(event.target.value)))
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div>

        <Header headerSubtitle={t("tasksScreen")}/>
        {/* <TextField id="outlined-search" label="Search field" type="search" size="small" onChange={handleSearchFieldChange}/> */}
      {tasks?.map((data) => {
        return (
          <TaskItemView
            active={data.active}
            name={data.name}
            taskOwnerEmail={data.taskOwnerEmail}
            taskOwnerName={data.taskOwnerName}
            taskId={data.taskId}
            key={data.taskId}
          />
        );
      })}
      <Button
        variant="text"
        color="primary"
        onClick={handleOpen}
      >
        <Stack alignItems={"center"}>

        <img src="baseline_add_circle_outline_black_48dp.png" alt="" style={{width:"60px"}}/>
        {t("createTask")}
        </Stack>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <TaskCreateDialog />
        </Box>
      </Modal>
    </div>


  );
}
