import Dialog from "@mui/material/Dialog";
import { TrackedTask, updateTrackedTask } from "../../../../features/localStorageDBHandler/localStorageDBHandler";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import TaskSelectComboBox from "../TaskSelectComboBox/TaskSelectComboBox";
import { useTranslation } from "react-i18next";
import "./TrackedTaskDetails.css";
import {FromDateTimeStringToDateTimeSelectorFormat, TimeToSeconds,SecondsToISOStringLocale} from "../../../../helpers/DateTimeFormaters"

type TrackedTaskDetailsProps = {
  trackedTaskDetailsDialogOpen: boolean;
  handleTrackedTaskViewClose: any;
  trackedTask: TrackedTask;
  editionMode:boolean;
};

export default function TrackedTaskDetails({
  trackedTaskDetailsDialogOpen,
  handleTrackedTaskViewClose,
  trackedTask,
  editionMode
}: TrackedTaskDetailsProps) {
  const { t } = useTranslation();
  const currentUserEmail = JSON.parse(localStorage.getItem("kttwEmail")!);

    const { taskId, name } = useSelector(
      (state: any) => state.selectedTaskReducer
    );
  //---------------------------------------------------------------------
  // STATE VARIABLES HERE
  //---------------------------------------------------------------------
  const [editedStartDate,setEditedStartDate] = useState<any>(trackedTask.taskStartDate);
  const [editedEndDate,setEditedEndDate] = useState<any>(trackedTask.taskEndDate);
  const [editedTimeInSeconds,setEditedTimeInSeconds] = useState(trackedTask.taskActiveTime);
  const [editedTimeToDisplay,setEditedTimeToDisplay] = useState(SecondsToISOStringLocale(trackedTask.taskActiveTime))
  const [comments,setComments] = useState(trackedTask.comments);
  const [editingTrackedTask,setEditingTrackedTask] = useState<boolean>(editionMode)
  //---------------------------------------------------------------------
  // HANDLERS HERE
  //---------------------------------------------------------------------
  const handleStartDateChange = (event:any)=>{
    let tempFechita = new Date(event.target.value).toString()
    setEditedStartDate(tempFechita)
  };
  const handleEndDateChange = (event:any)=>{
    let tempFechita = new Date(event.target.value).toString()
    setEditedEndDate(tempFechita)
  };
  const handleTimeInSecondsChange = (event:any)=>{
    setEditedTimeToDisplay(event.target.value)
    setEditedTimeInSeconds(TimeToSeconds(event.target.value))
  };
  const handleCommentsChange = (event:any)=>{
    setComments(event.target.value)
  };

  const handleUpdateButtonClick = ()=>{

        //TimeToSeconds(editedTimeInSeconds)
        let tempTrackedTask:TrackedTask = {
          comments:comments,
          taskActiveTime:editedTimeInSeconds,
          taskEndDate:editedEndDate,
          taskId:taskId,
          taskName:name,
          taskOwnerEmail:currentUserEmail,
          taskStartDate:editedStartDate,
          trackedtaskId:trackedTask.trackedtaskId
        };
        updateTrackedTask(tempTrackedTask);
        setEditingTrackedTask(false);
        handleTrackedTaskViewClose();
  }

  const handleEditButtonClick = ()=>{
    setEditingTrackedTask(true);
  };
  //---------------------------------------------------------------------
  // USE EFFECTS HERE
  //---------------------------------------------------------------------


  return (
    <Dialog
      open={trackedTaskDetailsDialogOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
      <TaskSelectComboBox taskSelectorActive={editingTrackedTask} />

        {/** AQUI LA PARTE DE LAS ENTRADAS PARA EL FORMULARIO DE LA TAREA */}
        <div className="TrackedTaskForm">
          {/**En mismo DIV fecha de inicio y de fin para el responsive */}
          <div className="TrackedTaskForm-StartEndDates">
            <div>
              <text>{t("trackedTaskFormStartDate")}</text>
              <input type="datetime-local" {...(editingTrackedTask===false?{disabled:true}:{})}  onChange={handleStartDateChange} value={FromDateTimeStringToDateTimeSelectorFormat(editedStartDate)}/>
            </div>

            <div>
              <text>{t("trackedTaskFormEndDate")}</text>
              <input {...(editingTrackedTask===false?{disabled:true}:{})} type="datetime-local" value={FromDateTimeStringToDateTimeSelectorFormat(editedEndDate)} onChange={handleEndDateChange}/>
            </div>
          </div>

          <div className="TrackedTaskForm-ActiveTime">
            <text>{t("trackedTaskFormTaskActiveTime")}</text>
            <input {...(editingTrackedTask===false?{disabled:true}:{})} type="time" value={editedTimeToDisplay} onChange={handleTimeInSecondsChange} />
          </div>

          <div className="TrackedTaskForm-Comments">
            <text>{t("trackedTaskFormComments")}</text>
            <textarea {...(editingTrackedTask===false?{disabled:true}:{})} className="TrackedTaskForm-CommentsInput" onChange={handleCommentsChange} value={comments}/>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        {editingTrackedTask===false?(<Button onClick={handleEditButtonClick}>Edit</Button>):(<Button onClick={handleUpdateButtonClick}>Update</Button>)}
        
        <Button onClick={handleUpdateButtonClick} autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
