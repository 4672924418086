import React from "react";
import { useState } from "react";
import Stack from "@mui/material/Stack";
import { Task,updateTask,deleteTask } from "../../../../features/localStorageDBHandler/localStorageDBHandler";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from '@mui/material/TextField'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Button from '@mui/material/Button'
import TaskDeleteDialog from "../TaskDeleteDialog/TaskDeleteDialog";
import { IconButton } from "@mui/material";
export default function TaskItemView(task: Task) {
    /**STATE VARIABLES Here */
    const [taskActive,setTaskActive] = useState(task.active)
    const [deleteTaskDialogOpen,setDeleteTaskDialogOpen] = useState(false);

    /**HANDLERS Here */
  const handleActiveChange = (event:any) => {
    updateTask(task.taskOwnerEmail,task.name,event.target.checked)
    setTaskActive(event.target.checked)
  };
  const handleDeleteTaskClick = ()=>{
    setDeleteTaskDialogOpen(true)
  }
  return (
    <Stack direction={"row"} spacing={1} justifyContent={"center"}>
        <TextField
          value={task.name}
          size="small"
        />
      <FormControlLabel
        label=""
        control={
          <Checkbox
            checked={taskActive}
            color="primary"
            onChange={handleActiveChange}
          />
        }
      />
      <IconButton aria-label="" onClick={handleDeleteTaskClick}>
      <DeleteForeverIcon fontSize="large" color="error"/>
      </IconButton>
      <TaskDeleteDialog userEmail={task.taskOwnerEmail} open={deleteTaskDialogOpen} taskName={task.name} setDeleteTaskDialogOpen={setDeleteTaskDialogOpen}/>
    </Stack>
  );
}
