import React, { useEffect, useState } from "react";
import Button from '@mui/material/Button'
import {deleteTrackedTask, getTrackedTasks,saveTrackedTask,TrackedTask} from "../../features/localStorageDBHandler/localStorageDBHandler"
import Header from "../../components/Header/Header";
import { t } from "i18next";
import TrackTaskController from "./components/TrackTaskController/TrackTaskController"
import TrackedtaskItemView from "./components/TrackedtaskItemView/TrackedTaskItemView"
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";



export default function TrackingScreen() {
    const currentUserEmail = JSON.parse(localStorage.getItem("kttwEmail")!); 
      /**-----------------------------------------------------------------------------------------------
   * STATE SELECTORS HERE
   * ----------------------------------------------------------------------------------------------*/

  const name = useSelector<RootState,string>((state)=> state.selectedTaskReducer.name); 

    /**--------------------------------------------------------------------
     * STATE VARIABLES HERE
     ---------------------------------------------------------------------*/
     const [trackedTasks,setTrackedTasks] = useState<Array<TrackedTask>>(getTrackedTasks(currentUserEmail))

     /**--------------------------------------------------------------------
     * USE EFFECTS HERE
     ---------------------------------------------------------------------*/
     useEffect(()=>{
        setTrackedTasks(getTrackedTasks(currentUserEmail))
     },[trackedTasks])

     useEffect(()=>{
      let tempTrackedTask:TrackedTask = {
        comments:"",
        taskActiveTime:0,
        taskEndDate:new Date(),
        taskId:"",
        taskName:"",
        taskOwnerEmail:currentUserEmail,
        taskStartDate:new Date(),
        trackedtaskId:"ERASE_ME",
      }
      saveTrackedTask(tempTrackedTask);//CREATE TEMP TASK AND THEN ERASE IT TO INITIALIZE DATA STRUCTURES
      deleteTrackedTask(tempTrackedTask);
   },[])

    /**HANDLERS HERE */
    const handlePrueba = ()=>{
        let trackedTask:TrackedTask={
            comments:"Comentarios de la tarea",
            taskActiveTime:60,
            taskEndDate:new Date(2023,9,29,21,30,0),
            taskStartDate:new Date(2023,9,29,17,30,0),
            taskId:"taskId",
            taskName:name as string,
            taskOwnerEmail:currentUserEmail,
            trackedtaskId:"trackedTaskId"
        }
        saveTrackedTask(trackedTask);
    }

    return(
        <div>
          <Header headerSubtitle={t("trackingScreen")}/>
          <TrackTaskController/>
          <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>

          {trackedTasks?.map((trackedTask)=>(
            <TrackedtaskItemView key={trackedTask.trackedtaskId} {...(trackedTask)}/>
          ))}
          </div>
        </div>
    )
}