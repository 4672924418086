export  function DateTimeToISOSringLocale(date:Date) {
    let tempDateString:string = "";
    let month = date.getMonth()+1;//EN LA FECHA JS LOS MESES EMPIEZAN CON INDICE 0, PERO ESTA GENTE QUY EPINGA LE PASA POR POCO ME VUELVEN LOCO
    tempDateString+=date.getFullYear().toString();
    tempDateString+="-";
    tempDateString+= month < 10?"0"+month.toString():month.toString();//Añadiendo cero para valores menores que 10
    tempDateString+="-";
    tempDateString+= date.getDay()<10?"0"+date.getDay().toString():date.getDay().toString();
    tempDateString+="T";
    tempDateString+= date.getHours()<10?"0"+date.getHours().toString():date.getHours().toString();
    tempDateString+=":";
    tempDateString+= date.getMinutes()<10?"0"+date.getMinutes().toString():date.getMinutes().toString();
    tempDateString+=":";
    tempDateString+= date.getSeconds()<10?"0"+date.getSeconds().toString():date.getSeconds().toString();
    return tempDateString
}

export function FromDateTimeStringToDateTimeSelectorFormat(dateTime:string):string {
    let temp:Date = new Date(dateTime)
    let year = temp.getFullYear();
    let day = temp.getDate();
    let month = temp.getMonth()+1;//Los graciositos de JS tiene indice cero para los meses ;)
    let hours = temp.getHours();
    let minutes = temp.getMinutes();
    let seconds = temp.getSeconds();

    let dateTimeToReturn:string = "";
    dateTimeToReturn+=year.toString();
    dateTimeToReturn+="-";
    dateTimeToReturn+=month<10?"0"+month.toString():month.toString();
    dateTimeToReturn+="-";
    dateTimeToReturn+=day<10?"0"+day.toString():day.toString();
    dateTimeToReturn+="T";
    dateTimeToReturn+=hours<10?"0"+hours.toString():hours.toString();
    dateTimeToReturn+=":";
    dateTimeToReturn+=minutes<10?"0"+minutes.toString():minutes.toString();
    dateTimeToReturn+=":";
    dateTimeToReturn+=seconds<10?"0"+seconds.toString():seconds.toString();   
    return dateTimeToReturn
}

export function SecondsToISOStringLocale(timeInSeconds:number) {
    let time:string = "";
    let hours:number = Math.floor(timeInSeconds/3600);
    let minutes:number = Math.floor((timeInSeconds - (hours*3600))/60);
    let seconds:number = timeInSeconds - (hours*3600) - (minutes*60);

    time+= hours < 10 ? `0${hours}`:hours;
    time+=":";
    time+= minutes < 10 ? `0${minutes}`:minutes;
    time+=":";
    time+= seconds < 10 ? `0${seconds}`:seconds;
    return time;
    
}

/**Se supone que recibes un string ocn este formato hh:mm:ss y entonces lo conviertes haciendo split y separandp
 * las horas, minutos y segundos y multiplicando por 3660, 60 y 1 ;)
 */
export function TimeToSeconds(time:string) {
    let tempHour = time.split(":")
    let segundos = Number.parseInt(tempHour[0])*3600 + Number.parseInt(tempHour[1])*60 + Number.parseInt(tempHour[2])
    return segundos;
}