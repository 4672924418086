import React, { useEffect, useState } from "react";
import { TrackedTask, deleteTrackedTask } from "../../../../features/localStorageDBHandler/localStorageDBHandler";
import "./TrackedtaskItemView.css";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useTranslation } from "react-i18next";
import calculateTimer from "../../../../helpers/CalculateTimer";
import TrackedTaskDetails from "../TrackedTaskDetails/TrackedTaskDetails";
import { updateTheSelectedTask } from "../../../../redux/actions/selectedTask";
import { useDispatch } from "react-redux";

export default function TrackedtaskItemView(trackedTask: TrackedTask) {
  const { t, i18n } = useTranslation();
  const dispatch:any = useDispatch();
  /**---------------------------------------------------------------------
   * STATE VARIABLES HERE
   ----------------------------------------------------------------------*/
  const [startTaskHours, setStartTaskHours] = useState("");
  const [startTaskMinutes, setStartTaskMinutes] = useState("");
  const [endTaskHours, setEndTaskHours] = useState("0");
  const [endTaskMinutes, setEndTaskMinutes] = useState("0");
  const [trackedTaskDetailsVisible, setTrackedTaskDetailsVisible] =
    useState<boolean>(false);
    const [openInEditMode,setOpenInEditMode] = useState<boolean>(false);//Este determina si el cuadro de detalles de la tarea se abre en modo edicion directamente o no. Para poder cubri rel caso en el cual le das click a Editar o a ver.
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const trackedTaskMenuOpen = Boolean(anchorEl);
  /**---------------------------------------------------------------------
   * USE EFFECTS HERE
   ----------------------------------------------------------------------*/
  useEffect(() => {
    let startTask = new Date(Date.parse(trackedTask.taskStartDate.toString()));
    setStartTaskHours(
      startTask.getHours() < 10
        ? "0" + startTask.getHours().toString()
        : startTask.getHours().toString()
    );
    setStartTaskMinutes(
      startTask.getMinutes() < 10
        ? "0" + startTask.getMinutes().toString()
        : startTask.getMinutes().toString()
    );
    let endTask = new Date(Date.parse(trackedTask.taskEndDate.toString()));
    setEndTaskHours(
      endTask.getHours() < 10
        ? "0" + endTask.getHours().toString()
        : endTask.getHours().toString()
    );
    setEndTaskMinutes(
      endTask.getMinutes() < 10
        ? "0" + endTask.getMinutes().toString()
        : endTask.getMinutes().toString()
    );

  }, []);
  /**---------------------------------------------------------------------
   * HANDLERS HERE
   ----------------------------------------------------------------------*/
  const handleTrackedTaskMenuClose = () => {
    setAnchorEl(null);
  };
  const handleTrackedTaskMenuClick: any = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleViewTrackedTaskClick = () => {
    dispatch(updateTheSelectedTask(trackedTask.taskId,trackedTask.taskName))
    setTrackedTaskDetailsVisible(true);
    handleTrackedTaskMenuClose();
  };
  const handleDeleteTrackedTaskClick = ()=>{
    deleteTrackedTask(trackedTask)
  };
  const handleTrackedTaskDetailsViewClose = () => {
    setTrackedTaskDetailsVisible(false);
  };
  return (
    <div className="TrackedtaskItemView">
      <div className="TrackedtaskItemView-TaskName">
        <text>{trackedTask.taskName}</text>
      </div>
      {/** Este DIV es para agrupar Hora de inicio y hora de terminado para el  responsive. Para que lo muestre como
       * inline block en dispositivos moviles.
       */}
      <div style={{ display: "flex" }}>
        <div className="TrackedtaskItemView-StartHour">
          <text>
            {startTaskHours}
            {":"}
            {startTaskMinutes}
          </text>
        </div>
        <div>
          <text>{"-"}</text>
        </div>
        <div className="TrackedtaskItemView-EndHour">
          <text className="TrackedtaskItemView-EndHour">
            {endTaskHours}
            {":"}
            {endTaskMinutes}
          </text>
        </div>
      </div>
      {/**DEntro de este div voy a ameter al total y al boton con las opciones para la tarea, ya sea borrar, editar loq ue sea. */}
      <div>
        <div className="TrackedTaskItemView-Total">
          <text>
            {"Total: "}
            {calculateTimer(trackedTask.taskActiveTime)[0]}
            {":"}
            {calculateTimer(trackedTask.taskActiveTime)[1]}
            {":"}
            {calculateTimer(trackedTask.taskActiveTime)[2]}
          </text>
          <IconButton
            id="traked-task-menu"
            aria-label="traked-task-menu"
            aria-controls={trackedTaskMenuOpen ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={trackedTaskMenuOpen ? "true" : undefined}
            onClick={handleTrackedTaskMenuClick}
          >
            <MoreVertIcon />
          </IconButton>
        </div>
      </div>

      {/**MENU SECTION HERE */}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={trackedTaskMenuOpen}
        onClose={handleTrackedTaskMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleViewTrackedTaskClick}>
          <IconButton aria-label="">
            <RemoveRedEyeIcon color="primary" />
          </IconButton>
          {t("viewTrackedTask")}
        </MenuItem>
        <MenuItem onClick={handleDeleteTrackedTaskClick}>
          <IconButton aria-label="">
            <DeleteForeverIcon color="error" />
          </IconButton>
          {t("deleteTrackedTask")}
        </MenuItem>
      </Menu>

      {/**TASK DETAILS MODAL HERE */}
      <TrackedTaskDetails
        trackedTask={trackedTask}
        handleTrackedTaskViewClose={handleTrackedTaskDetailsViewClose}
        trackedTaskDetailsDialogOpen={trackedTaskDetailsVisible}
        editionMode = {openInEditMode}
      />
    </div>
  );
}
