import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  getTask,
  getTasks,
  Task,
} from "../../../../features/localStorageDBHandler/localStorageDBHandler";
import { updateTheSelectedTask } from "../../../../redux/actions/selectedTask";
import { useDispatch, useSelector } from "react-redux";
import "./TaskSelectComboBox.css"

type TaskSelectComboBoxProps = {
  taskSelectorActive:boolean
}

export default function BasicSelect({taskSelectorActive}:TaskSelectComboBoxProps) {
  const currentUserEmail = JSON.parse(localStorage.getItem("kttwEmail")!);
  const [tasks, SetTasks] = React.useState(getTasks(currentUserEmail));
  const [selectedTask, setSelectedTask] = React.useState("");

  const handleChange = (event: SelectChangeEvent) => {
    let tempTask = getTask(currentUserEmail,event.target.value)
    setSelectedTask(tempTask?.name as string);
    dispatch(updateTheSelectedTask(tempTask?.taskId as string, event.target.value as string));
  };

  const dispatch: any = useDispatch();

  const { taskId, name } = useSelector(
    (state: any) => state.selectedTaskReducer
  );

  /**USE EFFECTS Here */
  React.useEffect(() => {
    SetTasks(getTasks(currentUserEmail));
  }, [tasks]);

  React.useEffect(()=>{
  },[]);



  return (
    <div>
      <FormControl className="TaskSelectComboBox--ComboBox">
        <InputLabel id="demo-simple-select-label" >Select Task</InputLabel>
        <Select
        size="small"
        {...(taskSelectorActive?{}:{disabled:true})}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={name}
          label="Select Taskl,klk"
          onChange={handleChange}
          style={{ textAlign: "left" }}
        >
          {tasks?.map((task) => (
            <MenuItem value={task.name}>{task.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
