import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Drawer from "@mui/material/Drawer";
import UserInfoChip from "./components/UserInfoChip/UserInfoChip";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import './styles.css'
import { useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";

type HeaderProps = {
  headerSubtitle: string;
};

export default function Header({ headerSubtitle }: HeaderProps) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  /**-----------------------------------------------------------------------------------------------
   * STATE VARIABLES HERE
   * ----------------------------------------------------------------------------------------------*/
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [stopTrackingBeforeLeaveAlert,setStopTrackingBeforeLeaveAlert] = useState(false);

  /**-----------------------------------------------------------------------------------------------
   * STATE SELECTORS HERE
   * ----------------------------------------------------------------------------------------------*/
  /** Los estados para el control del temporizador. */
  const { isRunning, isPaused } = useSelector(
    (state: any) => state.timerReducer
  );

  /**-----------------------------------------------------------------------------------------------
   * HANDLERS HERE
   * ----------------------------------------------------------------------------------------------*/
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  const handleTrackTasksClick = () => {
    handleDrawerClose();
    navigate("/trackingscreen");
  };
  const handleManageTasksClick = () => {
    if(isPaused===true||isRunning===true){
      console.log(isPaused)//XXX
      console.log(isRunning)//XXX
      handleDrawerClose();
      setStopTrackingBeforeLeaveAlert(true);
    }else{
      setStopTrackingBeforeLeaveAlert(false);
      handleDrawerClose();
      navigate("/tasksscreen");
    }
  };
  const handleChangeUserClick = () => {
    if(isPaused===true||isRunning===true){
      handleDrawerClose();
      setStopTrackingBeforeLeaveAlert(true);
    }else{
      setStopTrackingBeforeLeaveAlert(false);
      handleDrawerClose();
      navigate("/");
    }
  };
  return (
    <Stack>

    <Stack
      marginBottom={4}
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-around"}
      width={"100%"}
      sx={{ backgroundColor: "#152932", color: "white" }}
    >
      <img src="KRONOS SPLASH.png" alt="" width={100} height={100} />
      <Stack >
        <Typography variant="h5" color="white">
          Kronos Time Tracker Web
        </Typography>
        <Typography variant="subtitle1" color="orange">
          {headerSubtitle}
        </Typography>
      </Stack>
      
      
      <Stack direction={"row"} spacing={2} >
        
        <div className="personal-info">
          <Typography variant="subtitle1" color="white">
            {JSON.parse(localStorage.getItem("kttwCurrentName")!)}
          </Typography>
          <Typography variant="subtitle2" color="white">
            {JSON.parse(localStorage.getItem("kttwEmail")!)}
          </Typography>
        </div>
        <IconButton
          aria-label=""
          onClick={handleDrawerOpen}
          sx={{ color: "white" }}
        >
          <MenuIcon fontSize="large"/>
        </IconButton>
      </Stack>
      <Drawer
        variant="temporary"
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}
      >
        <List>
          <UserInfoChip drawerOpen={handleDrawerOpen} />
          <Divider></Divider>
          <ListItem>
            <ListItemButton onClick={handleTrackTasksClick}>
              <ListItemIcon>{<AvTimerIcon />}</ListItemIcon>
              <ListItemText primary="Track Tasks" />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={handleManageTasksClick}>
              <ListItemIcon>{<ManageHistoryIcon />}</ListItemIcon>
              <ListItemText primary="Manage Tasks" />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={handleChangeUserClick}>
              <ListItemIcon>{<PersonOutlineIcon />}</ListItemIcon>
              <ListItemText primary="Change User" />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </Stack>


{stopTrackingBeforeLeaveAlert?(<Alert severity="error" onClose={() => {setStopTrackingBeforeLeaveAlert(false)}}>{t("stopTrackingBeforeLeaving")}</Alert>):(null)}

    
    </Stack>
  );
}
