import React from "react";
import Stack from "@mui/material/Stack"
import Avatar from "@mui/material/Avatar";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Typography from "@mui/material/Typography";
type UserInfoChipProps = {
    drawerOpen:any
}
export default function UserInfoChip({drawerOpen}:UserInfoChipProps) {
    return(
        <Stack direction={"row"} spacing={1} justifyItems={"center"} paddingLeft={1} paddingBottom={1} onClick={drawerOpen} >
            <Avatar sx={{ width: 56, height: 56 }}>
                <AccountCircleIcon fontSize="large"/>
            </Avatar>
            <Stack>
            <Typography variant="subtitle1">
                {JSON.parse(localStorage.getItem("kttwCurrentName")!)}
      </Typography>
      <Typography variant="subtitle2">
      {JSON.parse(localStorage.getItem("kttwEmail")!)}
      </Typography>
            </Stack>
        </Stack>
    );
}