import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import WelcomeScreen from "../views/WelcomeScreen"
import TrackingScreen from "../views/TrackingScreen/TrackingScreen"
import FourOFour from "../views/FourOFour"
import TasksScreen from "../views/TasksScreen/TasksScreen"

export default function RoutesComponent() {
    return(
        <BrowserRouter>
        <Routes>
            <Route path="/" element={<WelcomeScreen/>}/>
            <Route path="/welcomescreen" element={<WelcomeScreen/>}/>
            <Route path="/trackingscreen" element={<TrackingScreen/>}/>
            <Route path="/tasksscreen" element={<TasksScreen/>}/>
            <Route path="/*" element={<FourOFour/>} />
        </Routes>
        </BrowserRouter>
    );
}