import React from "react";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header/Header";
export default function FourOFour() {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Header headerSubtitle="404"/>
      <h1>{t("404Title")}</h1>
      <a href="/">{t("goSafe")}</a>
    </div>
  );
}
