import { createSlice } from "@reduxjs/toolkit";
interface selectedTaskState {
  taskId:string;
  name:string
}
const initialState:selectedTaskState = {
  taskId: "",
  name: "",
};

const selectedTask = createSlice({
  name: "selectedTask",
  initialState,
  reducers: {
    updateSelectedTask(state, action) {
      //state.taskOwnerName = action.payload;
      state.taskId = action.payload.taskId;
      state.name = action.payload.name;
    },
  },
});

const { actions, reducer } = selectedTask;
const {updateSelectedTask} = actions;
export {updateSelectedTask};
export default reducer;
