import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { TrackedTask, getTasks } from "../../../../features/localStorageDBHandler/localStorageDBHandler";
import React, { useEffect, useRef, useState } from "react";
import TaskSelectComboBox from "../TaskSelectComboBox/TaskSelectComboBox";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import StopIcon from "@mui/icons-material/Stop";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TaskCreateDialog from "../../../TasksScreen/components/TaskCreateDialog/TaskCreateDialog";
import Typography from "@mui/material/Typography";
import "./styles.css";
import calculateTimer from "../../../../helpers/CalculateTimer";
import { useDispatch, useSelector } from "react-redux";
import {
  updateTimer,
  startTimer,
  pauseTimer,
  stopTimer,
} from "../../../../redux/actions/timer";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button/Button";
import TrackedTaskForm from "../TrackedTaskForm/TrackedTaskForm"
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 280,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

let timeElapsed = 0; //Variable global para llevar el tiempo transcurrido en segundos.
let timePaused = 0;
let timeTick = Date.now();


export default function TrackTaskController() {
  const { t, i18n } = useTranslation();
  const dispatch: any = useDispatch(); //Para hacer los dispatch de las acciones para el trabajo con redux
  const currentUserEmail = JSON.parse(localStorage.getItem("kttwEmail")!);
  /**STATE VARIABLES HERE */
  const [createTaskDialogOpen, setCreateTaskDialogOpen] = useState(false); //Para mostrar el dialogo de crear una nueva tarea.
  const [timerArray, setTimerArray] = useState<Array<number | string>>([]); //Aqui se almacena horas, minutos,segundos con formato correcto para mostrar en la UI
  const [intervalId, setIntervalId] = useState<number>(0); //Este es el que me permite detener la tarea ciclica.
  const [pausedId,setPausedId] = useState<number>(0);//Permite detener el conteo de tiempo en pause.
  const [saveTrackedTaskDialogOpen,setSaveTrackedTaskDialogOpen] = useState<boolean>(false);//Para mostrar y ocultar el dialog de salvar la tarea creada
  const [trackedTask,setTrackedTask] = useState<TrackedTask>({comments:"",taskActiveTime:0,taskEndDate:new Date(),taskId:"",taskName:"",taskOwnerEmail:"",taskStartDate:new Date(),trackedtaskId:""});

  /**-----------------------------------------------------------------------------------------------
   * STATE SELECTORS HERE
   * ----------------------------------------------------------------------------------------------*/
  /** Los estados para el control del temporizador. */
  const { isRunning, isPaused, isStopped, timeInSeconds,startDate,endDate } = useSelector(
    (state: any) => state.timerReducer
  );
  const { taskId, name } = useSelector(
    (state: any) => state.selectedTaskReducer
  );

  /**-----------------------------------------------------------------------------------------------
   * USE EFFECTS HERE
   * ----------------------------------------------------------------------------------------------*/
  /** Para que se actualice el conteo cada vez que la variable de estado timeInsecondsCambia */
  useEffect(() => {
    let timeArray: Array<number | string> = calculateTimer(timeInSeconds);
    setTimerArray(timeArray);
  }, [timeInSeconds]);

  /**HANDLERS HERE */
  const handleCreateTaskDialogClose = () => {
    setCreateTaskDialogOpen(false);
  };
  const handleCreateTaskDialogOpen = () => {
    setCreateTaskDialogOpen(true);
  };
  const handleStartCount = () => {
    if (name!=="")//Obligar a que se seleccione una tarea para poder hacer tracking
    {

      /**STATE MANIPULATION */
      dispatch(startTimer());
      /**START COUNTER */
      timeTick = Date.now();
      let interval: any = setInterval(() => {
        //Saber si el tiempo que ha pasado es mayor que 1 segundo. De ser asi pues es necesario actualizar 
        //mas de 1 segundo. Esto puede pasar porque minizas el browser, o pierde el foco. Cosas asi.
        let timeTickDifference = Math.floor(Date.now()/1000 - timeTick/1000)
        if(timeTickDifference>1)//Si es mayor que 1 pues ha pasado mas de 1 segundo desde la ultima vez que pasaste por aqui ;)
        {
          timeElapsed+=timeTickDifference;
        }else{
          timeElapsed++;
        }
        timeTick = Date.now();
            
          dispatch(updateTimer(timeElapsed-timePaused));
      }, 1000);
      setIntervalId(interval);
      /**STOP COUNTER*/
      clearInterval(pausedId);
    }else{
      alert(t("selectTaskBeforeTracking"))
    }
  };
  const handleStopCount = () => {
    /**STOP COUNTER */
    clearInterval(intervalId);
    clearInterval(pausedId)
    /**Show Screen to Save the tracked Task ;) */
    setSaveTrackedTaskDialogOpen(true);
    dispatch(stopTimer());
    timeElapsed = 0;
    timePaused = 0;
  };
  const handlePauseCount = () => {
    /**STATE MANIPULATION */
    dispatch(pauseTimer());
    let interval: any = setInterval(() => {
        //Saber si el tiempo que ha pasado es mayor que 1 segundo. De ser asi pues es necesario actualizar 
        //mas de 1 segundo. Esto puede pasar porque minizas el browser, o pierde el foco. Cosas asi.
        let timeTickDifference = Math.floor(Date.now()/1000 - timeTick/1000)
        if(timeTickDifference>1)//Si es mayor que 1 pues ha pasado mas de 1 segundo desde la ultima vez que pasaste por aqui ;)
        {
          timePaused+=timeTickDifference;
          timeElapsed+=timeTickDifference;
        }else{
          timePaused++;
          timeElapsed++;
        }
        timeTick = Date.now();
      
}, 1000);
   setPausedId(interval);
    clearInterval(intervalId);
  };
  const handleSaveTrackedTaskClose = ()=>{
    setSaveTrackedTaskDialogOpen(false);
  }




  return (
    <div className="track_task_controller">
      <div style={{ margin: 1 }}>
        <TaskSelectComboBox taskSelectorActive={isStopped}/>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h6" color="black">
          {timerArray[0]}
          {":"}
          {timerArray[1]}
          {":"}
          {timerArray[2]}
        </Typography>
        <IconButton
          color="success"
          onClick={handleStartCount}
          {...(isRunning ? { disabled: true } : {})}
        >
          <PlayArrowIcon fontSize="large" />
        </IconButton>
        <IconButton
          color="warning"
          onClick={handlePauseCount}
          {...(isPaused || isStopped ? { disabled: true } : {})}
        >
          <PauseIcon fontSize="large" />
        </IconButton>
        <IconButton
          color="error"
          onClick={handleStopCount}
          {...(isStopped ? { disabled: true } : {})}
        >
          <StopIcon fontSize="large" />
        </IconButton>
        <IconButton
          color="primary"
          onClick={handleCreateTaskDialogOpen}
          {...(isStopped ? {} : { disabled: true })}
        >
          <AddCircleOutlineIcon fontSize="large" />
        </IconButton>
      </div>

      {/**---------------------------------------------------------------------------------- */}
      {/** AUXILIARY WINDOWS AND MODALS HERE*/}
      {/**---------------------------------------------------------------------------------- */}
      
      {/** CREATE TASK MODAL WINDOW HERE */}
      <Modal
        open={createTaskDialogOpen}
        onClose={handleCreateTaskDialogClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <TaskCreateDialog />
        </Box>
      </Modal>

      {/** SAVE TRACKED TASK MODAL WINDOW HERE */}
      {saveTrackedTaskDialogOpen?(<TrackedTaskForm handleSaveTrackedTaskClose={handleSaveTrackedTaskClose} saveTrackedTaskDialogOpen={saveTrackedTaskDialogOpen} trackedTask={trackedTask}/>):(undefined)}
      
    </div>
  );
}
