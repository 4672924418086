import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Container from "@mui/material/Container";
import { Stack, TextField, Button } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useNavigate } from "react-router-dom";
import { Task } from "../../features/localStorageDBHandler/localStorageDBHandler";
export default function WelcomeScreen() {
  /**------------------------------------------------------------------------------------------
   * STATES HERE
   * ------------------------------------------------------------------------------------------*/
  const [name, setName] = useState("");
  const [nameValid,setNameValid] = useState(true);
  const [email, setEmail] = useState("");
  const [emailValid,setEmailValid] = useState(true);
  const [userTypedOnNameField,setUserTypedOnNameField] = useState(false);
  const [userTypedOnEmailField,setUserTypedOnEmailField] = useState(false);
  /**------------------------------------------------------------------------------------------
   * VALIDATIONS HERE
   * ------------------------------------------------------------------------------------------*/
  const emailValidation = (email:string) => {
    const regex = new RegExp(
      "^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$"
    );
    return regex.test(email);
  };
  const nameValidation = (name:string) => {
    if(name){
        const regex = new RegExp("(\\w*)$");
        return regex.test(name);
    } else{
        return false;
    }
  };
  /**------------------------------------------------------------------------------------------
   * HANDLERS HERE
   * ------------------------------------------------------------------------------------------*/
  const handleGetStartedClick = () => {
    /**Si es distinto de null es que ya existe esta clave creada, si es null es que no existe y la vamos a crear
     * como un arreglo vacío.
     */
    if (JSON.parse(localStorage.getItem("kttwTasks_" + email)!)!==null) {
      
      if (emailValid&&nameValid&&userTypedOnNameField&&userTypedOnEmailField) {        
          localStorage.setItem("kttwCurrentName", JSON.stringify(name));
          localStorage.setItem("kttwEmail", JSON.stringify(email));
          
          navigate("/trackingscreen");
          let sampleTask:Task={active:true,name:"Sample Task",taskOwnerEmail:JSON.parse(localStorage.getItem("kttwEmail")!),taskOwnerName:JSON.parse(localStorage.getItem("kttwCurrentName")!)}
      } else{
          setNameValid(nameValidation(name))
          setEmailValid(emailValidation(email));
      }
    }else{//Establecer arreglo vacio cuando el user es nuevo y nuca habia entrado
      localStorage.setItem("kttwTasks_" + email,"[]")
    }
  };
  const handleNameFieldChange = (event: any) => {
    setUserTypedOnNameField(true);
    if (event.target.value) {        
        setName(event.target.value.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
        setNameValid(nameValidation(event.target.value))
    } else{
        setName("");
        setNameValid(false);
    }
  };
  const handleEmailFieldChange = (event: any) => {
    setUserTypedOnEmailField(true);
    setEmail(event.target.value);
    setEmailValid(emailValidation(event.target.value));
  };

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  
  return (
    <Container style={{ backgroundColor: "#152932" }} maxWidth="xl">
      <Container maxWidth="md" style={{ backgroundColor: "#152932" }}>
        <Stack direction={"column"} alignItems={"center"}>
          <img src="KRONOS SPLASH.png" alt="kronos splash" width={200} />
          <h1 style={{ color: "white" }}>
            {t("welcomeScreenDescription1")}{" "}
            <span style={{ color: "#4ADFF3" }}>
              {t("welcomeScreenDescription2")}
            </span>{" "}
            {t("welcomeScreenDescription3")}
          </h1>
          <Container maxWidth="xs">
            <Stack
              spacing={4}
              style={{
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "5px",
              }}
            >
              <TextField
                variant="outlined"
                id="name"
                label="Name"
                placeholder={t("typeYourName")}
                size="small"
                onChange={handleNameFieldChange}
                value={name}
                error={!nameValid}
                helperText={nameValid?"":t("incorrectName")}
              />
              <TextField
                variant="outlined"
                id="email"
                label="Email"
                placeholder={t("typeYourEmail")}
                size="small"
                onChange={handleEmailFieldChange}
                value={email}
                error={!emailValid}
                helperText={emailValid?"":t("incorrectEmail")}
              />
              <Button
                variant="contained"
                color="primary"
                startIcon={<SendIcon />}
                onClick={handleGetStartedClick}
              >
                {t("getStarted")}
              </Button>
            </Stack>
          </Container>
          <img
            src="AutomaticMenTransparent Full White 1.png"
            alt="automaticme logo"
          />
          <h4 style={{ color: "white" }}>
            Automatic Men Tecnologías Digitales 2023
          </h4>
        </Stack>
      </Container>
    </Container>
  );
}
