import { createAction } from "@reduxjs/toolkit";
import {
    requestTimerStart as requestTimerStartSlice,
    requestTimerPause as requestTimerPauseSlice,
    requestTimerStop as requestTimerStopSlice,
    updateTimerCount as updateTimerCountSlice
} from "../slices/timer";
export const requestTimerStart = createAction("REQUEST_TIMER_START") 
export const requestTimerPause = createAction("REQUEST_TIMER_PAUSE") 
export const requestTimerStop = createAction("REQUEST_TIMER_STOP")

export const startTimer = ()=>async (dispatch)=>{
    dispatch(requestTimerStartSlice());
}
export const pauseTimer = ()=>async (dispatch)=>{
    dispatch(requestTimerPauseSlice());
}
export const stopTimer = ()=>async (dispatch)=>{
    dispatch(requestTimerStopSlice());
}
export const updateTimer = (timeInSeconds)=>async (dispatch)=>{
    dispatch(updateTimerCountSlice(timeInSeconds));
}