import React from 'react';
import './App.css';
import RoutesComponent from "./routes"

function App() {
  return (
    <div className="App">
      <RoutesComponent/>
    </div>
  );
}

export default App;
