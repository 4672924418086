import Dialog from "@mui/material/Dialog"
import {Task} from "../../../../features/localStorageDBHandler/localStorageDBHandler"
import DialogTitle from "@mui/material/DialogTitle"
import React, { useState } from "react"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogActions from "@mui/material/DialogActions"
import Button from "@mui/material/Button"
import {deleteTask} from "../../../../features/localStorageDBHandler/localStorageDBHandler"
import { useTranslation } from "react-i18next"

type TaskDeleteDialogProps = {
  userEmail:string,
  taskName:string,
  open:boolean,
  setDeleteTaskDialogOpen:any
}
export default function TaskDeleteDialog({userEmail,taskName,open,setDeleteTaskDialogOpen}:TaskDeleteDialogProps) {
  const { t, i18n } = useTranslation();
  
  /**HANDLERS Here */
  const handleClose = ()=>{
    setDeleteTaskDialogOpen(false)
  }
  const handleDeleteTaskClick =()=>{
    deleteTask(userEmail,taskName)
  }
  return (

    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("deleteTaskDialogTitle")}{taskName}{"?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("deleteTaskDialogDescription")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("deleteTaskDialogCancel")}</Button>
          <Button onClick={handleDeleteTaskClick} autoFocus>
            {t("deleteTaskDialogDelete")}
          </Button>
        </DialogActions>
      </Dialog>
  );
}