import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    isRunning:false,
    isPaused:false,
    isStopped:true,
    timeInSeconds:0,
    startDate:new Date(Date.now()).toString(),
    endDate:new Date(Date.now()).toString()
}

const timer = createSlice({
    name:"timer",
    initialState,
    reducers:{
        requestTimerStart(state,action){
            state.isPaused = false;
            state.isRunning = true;
            state.isStopped = false;
            state.startDate = new Date(Date.now()).toString();
        },
        requestTimerPause(state,action){
            state.isPaused = true;
            state.isRunning = false;
            state.isStopped = false;
        },
        requestTimerStop(state,action){
            state.isPaused = false;
            state.isRunning = false;
            state.isStopped = true;
            state.endDate = new Date(Date.now()).toString();
        },
        updateTimerCount(state,action){
            state.timeInSeconds = action.payload;

        }
    }
})

const {actions,reducer} = timer;
const {requestTimerStart,requestTimerPause,requestTimerStop,updateTimerCount} = actions;
export {requestTimerStart,requestTimerPause,requestTimerStop,updateTimerCount};
export default reducer;