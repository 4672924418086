/**-----------------------------------------------------------------
 * EVERYTHING RELATED TO TASKS HERE
 *------------------------------------------------------------------*/
export interface Task {
  taskOwnerName: string;
  taskOwnerEmail: string;
  taskId?: string; //Como este approach es local voy a gener al id en base a la fecha y la hora ya que no se van a crear dos tasks al mismo tiempo.
  name: string; //El nombre que le quiera poner el que va a crear la tarea. No puede estar en blanco.
  active: boolean;
}

/**---------------------------------------------------------------------------------------
 * Estoy creando esta estructura para enviar la respuesta del proceso de creacion de una tarea. Tengo un boolean para
 * decir si la tarea fue creada de forma correcta y un campo para enviar un mensaje referente a la creacion de la tarea.
 */
export interface TaskCreationResponse {
  taskCreated: boolean;
  taskCreationMessage: string;
}
export const getTasks = (email: string) => {
  let tasks: Task[] = JSON.parse(localStorage.getItem("kttwTasks_" + email)!);
  return tasks;
};
export const getTask = (email:string,taskName:string)=>{
  let tasks: Task[] = JSON.parse(localStorage.getItem("kttwTasks_" + email)!);
  let foundTask = tasks.find((task:Task)=> task.name === taskName)
  return foundTask;
}
export const updateTask = (
  email: string,
  taskName: string,
  active: boolean
) => {
  let tasks: Task[] = JSON.parse(localStorage.getItem("kttwTasks_" + email)!);
  let foundItemIndex = tasks.findIndex((task: Task) => task.name === taskName);
  tasks[foundItemIndex].active = active;
  localStorage.setItem(
    "kttwTasks_" + JSON.parse(localStorage.getItem("kttwEmail")!),
    JSON.stringify(tasks)
  );
};
export const deleteTask = (email: string, taskName: string) => {
  let tasks: Task[] = JSON.parse(localStorage.getItem("kttwTasks_" + email)!);
  tasks = tasks.filter((task: Task) => task.name !== taskName);
  localStorage.setItem(
    "kttwTasks_" + JSON.parse(localStorage.getItem("kttwEmail")!),
    JSON.stringify(tasks)
  );
};
export const taskExists = (email: string, name: string) => {
  let tasks: Task[] = JSON.parse(localStorage.getItem("kttwTasks_" + email)!);
  return tasks.find(
    (task: Task) => task.name.toLowerCase() === name.toLowerCase()
  );
};
export function saveTask(task: Task) {
  let taskCreationResult: TaskCreationResponse = {
    taskCreated: false,
    taskCreationMessage: "",
  };
  if (localStorage.getItem("kttwTasks_" + task.taskOwnerEmail)!) {
    //Si llegas aqui significa que este item si existe en local storag por lo tanto tienes que comprobar si existe o no el task
    let foundTasks: Task[] = getTasks(task.taskOwnerEmail);
    if (foundTasks) {
      if (taskExists(task.taskOwnerEmail, task.name)) {
        taskCreationResult.taskCreated = false;
        taskCreationResult.taskCreationMessage = "taskAlreadyExists";
        return JSON.stringify(taskCreationResult);
      } else {
        if (task.name.length < 5) {
          taskCreationResult.taskCreated = false;
          taskCreationResult.taskCreationMessage = "taskLengthError";
          return JSON.stringify(taskCreationResult);
        }
        task.taskId = Date.now().toString();
        foundTasks.push(task);
        localStorage.setItem(
          "kttwTasks_" + JSON.parse(localStorage.getItem("kttwEmail")!),
          JSON.stringify(foundTasks)
        );
        taskCreationResult.taskCreated = true;
        taskCreationResult.taskCreationMessage = "taskCreatedSuccessfuly";
        return JSON.stringify(taskCreationResult);
      }
    } else {
      taskCreationResult.taskCreated = false;
      taskCreationResult.taskCreationMessage = "Not sure when it gets here ;)";
      return JSON.stringify(taskCreationResult);
    }
  } else {
    //Si llegas aqui es porque no existe este iten en local storage y puedes crearlo sin susto
    let tasks: Task[] = [];
    task.taskId = Date.now().toString();
    tasks.push(task);
    localStorage.setItem(
      "kttwTasks_" + JSON.parse(localStorage.getItem("kttwEmail")!),
      JSON.stringify(tasks)
    );
    taskCreationResult.taskCreated = true;
    taskCreationResult.taskCreationMessage = "taskCreatedSuccessfuly";
    return JSON.stringify(taskCreationResult);
  }
}

/**-----------------------------------------------------------------
 * EVERYTHING RELATED TO TASKS ENDS HERE
 *------------------------------------------------------------------*/

/**-----------------------------------------------------------------
 * EVERYTHING RELATED TO TRACKED TASKS STARTS HERE
 *------------------------------------------------------------------*/

export interface TrackedTask {
  trackedtaskId: string; //"Combinacion de dia fecha y hora",
  taskId: string; //"el id de la tarea, el que pertence a ese task",
  taskName: string; //el nombre de la tarea, fallback aqui si el id no se encuentra en bd
  taskOwnerEmail: string; //el amial del dueño de la tarea que queremos guardar
  taskStartDate: Date; //"Fecha de inicio, se incluye la hora",
  taskEndDate: Date; //"fecha de finalizacion, se incluye la hora",
  taskActiveTime: number; //"El tiempo real que la atea estuvo activa. Ojo no hagas ningun calculo raro de restar fechas ni nada de eso. Usa este tiempo, porque si estaba en poausa o algo pues habran diferencias.",
  comments: string; //"COMENTARIOS SOBRE LA TAREA REALIZADA"
}
export interface kronosUser {
  name: string;
  email: string;
  trackedTasks: TrackedTask[];
  tasks: Task[];
}
export const getTrackedTasks = (email: string) => {
  let trackedTasks: TrackedTask[] = JSON.parse(
    localStorage.getItem("kttwTrackedTasks_" + email)!
  );
  return trackedTasks;
};

export function saveTrackedTask(trackedTaskToCreate: TrackedTask) {
  let taskCreationResult: TaskCreationResponse = {
    taskCreated: false,
    taskCreationMessage: "",
  };
  if (
    localStorage.getItem(
      "kttwTrackedTasks_" + trackedTaskToCreate.taskOwnerEmail
    )!
  ) {
    //Si llegas aqui significa que este item si existe en local storage
    let foundTrackedTasks: TrackedTask[] = getTrackedTasks(
      trackedTaskToCreate.taskOwnerEmail
    );
    if (foundTrackedTasks) {
      trackedTaskToCreate.trackedtaskId = Date.now().toString();
      foundTrackedTasks.push(trackedTaskToCreate);
      localStorage.setItem(
        "kttwTrackedTasks_" + JSON.parse(localStorage.getItem("kttwEmail")!),
        JSON.stringify(foundTrackedTasks)
      );
      taskCreationResult.taskCreated = true;
      taskCreationResult.taskCreationMessage = "taskCreatedSuccessfuly";
      return JSON.stringify(taskCreationResult);
    } else {
      taskCreationResult.taskCreated = false;
      taskCreationResult.taskCreationMessage = "Not sure when it gets here ;)";
      return JSON.stringify(taskCreationResult);
    }
  }else{
    let temp:TrackedTask[] = [];
    trackedTaskToCreate.trackedtaskId = Date.now().toString();
    temp.push(trackedTaskToCreate)
      localStorage.setItem(
        "kttwTrackedTasks_" + JSON.parse(localStorage.getItem("kttwEmail")!),
        JSON.stringify(temp)
      );
      taskCreationResult.taskCreated = true;
      taskCreationResult.taskCreationMessage = "taskCreatedSuccessfuly";
      return JSON.stringify(taskCreationResult);
  }
}

export function updateTrackedTask(trackedTaskToUpdate: TrackedTask) {
  let tasks: TrackedTask[] = JSON.parse(localStorage.getItem("kttwTrackedTasks_" + trackedTaskToUpdate.taskOwnerEmail)!);
  let foundItemIndex = tasks.findIndex((task: TrackedTask) => task.trackedtaskId === trackedTaskToUpdate.trackedtaskId);
  tasks[foundItemIndex].comments = trackedTaskToUpdate.comments;
  tasks[foundItemIndex].taskActiveTime = trackedTaskToUpdate.taskActiveTime;
  tasks[foundItemIndex].taskEndDate = trackedTaskToUpdate.taskEndDate;
  tasks[foundItemIndex].taskId = trackedTaskToUpdate.taskId;
  tasks[foundItemIndex].taskName = trackedTaskToUpdate.taskName;
  tasks[foundItemIndex].taskOwnerEmail = trackedTaskToUpdate.taskOwnerEmail;
  tasks[foundItemIndex].taskStartDate = trackedTaskToUpdate.taskStartDate;
  tasks[foundItemIndex].trackedtaskId = trackedTaskToUpdate.trackedtaskId;
  localStorage.setItem(
    "kttwTrackedTasks_" + JSON.parse(localStorage.getItem("kttwEmail")!),
    JSON.stringify(tasks)
  );
}

export const deleteTrackedTask = (trackedTaskToDelete:TrackedTask) => {
  let tasks: TrackedTask[] = JSON.parse(localStorage.getItem("kttwTrackedTasks_" + trackedTaskToDelete.taskOwnerEmail)!);
  tasks = tasks.filter((task: TrackedTask) => task.trackedtaskId !== trackedTaskToDelete.trackedtaskId);
  localStorage.setItem(
    "kttwTrackedTasks_" + JSON.parse(localStorage.getItem("kttwEmail")!),
    JSON.stringify(tasks)
  );
};

/** Que devuelva las atreas correspondientes a la semana en la que se ejecuta el metodo. La semana va de Lunes a domingo. */
export const getThisWeekTasks = (email: string)=>{
  let trackedTasks = getTrackedTasks(email);
  let tasks:TrackedTask[] = trackedTasks.filter((task:TrackedTask)=>task.taskStartDate)
}

/**Devolver los dias que conformal la semana del momento en el cual es ejecutada la funcion. */
export const getWeekDays = ()=>{
  // let weekStart = DateTime.now().startOf('week');
  //   for (let week=1 ; week <= numberOfWeek ; week++ ){
  //     const weekDays = [];
  //     for(let day=0 ; day < numberOfDays; day++ ){
  //       weekDays.push(weekStart.plus({ days: day }).toFormat('yyyy-MM-dd'))
  //     }
  //     this.weeks.push(weekDays);
  //     weekStart = weekStart.plus({ days: 7 });
  //   }
}

/**-----------------------------------------------------------------
 * EVERYTHING RELATED TO TRACKED TASKS ENDS HERE
 *------------------------------------------------------------------*/